<template>
    <div class="table_container"  style="height: 360px !important; display: flex; flex-direction: column; justify-content: space-between;">
        <table class="table list-content" >
            <thead class="">
            <tr>
                <th style="text-align: left; padding-left: 14px">Browser

                </th>
                <th class="cursor_pointer sorted_row" style="text-align: center;"
                    @click.prevent="browser_sorting = (browser_sorting === 'clicks_asc') ? 'clicks_desc': 'clicks_asc'">
                    Clicks

                    <i v-if="browser_sorting==='clicks_asc'"
                       class="first table_filter_caret fas fa-caret-up"></i>
                    <i v-if="browser_sorting==='clicks_desc'"
                       class="table_filter_caret fas fa-caret-down"></i>

                </th>

                <th class="cursor_pointer sorted_row" style="text-align: center;"
                    @click.prevent="browser_sorting = (browser_sorting === 'unique_clicks_asc') ? 'unique_clicks_desc': 'unique_clicks_asc'"
                >
                    Unique Clicks

                    <i v-if="browser_sorting==='unique_clicks_asc'"
                       class="first table_filter_caret fas fa-caret-up"></i>
                    <i v-if="browser_sorting==='unique_clicks_desc'"
                       class="table_filter_caret fas fa-caret-down"></i>
                </th>
                <th class="cursor_pointer sorted_row" style="text-align: center;"
                <th v-if="!forBio" class="cursor_pointer sorted_row" style="text-align: center;"
                    @click.prevent="browser_sorting = (browser_sorting === 'conversions_asc') ? 'conversions_desc': 'conversions_asc'"
                >
                    Conversions
                    <i v-if="browser_sorting==='conversions_asc'"
                       class="first table_filter_caret fas fa-caret-up"></i>
                    <i v-if="browser_sorting==='conversions_desc'"
                       class="table_filter_caret fas fa-caret-down"></i>
                </th>
                <th v-if="!forBio" class="cursor_pointer sorted_row" style="text-align: center;">Conv. rate
                </th>
            </tr>
            </thead>
            <tbody class="list-content-body" >
            <tr v-if="statistics.aggregations.visited_from_browsers.buckets.length == 0">
                <td colspan="9">
                    <div class="col">
                        <p class="text-center">
                            <no-events-available></no-events-available>
                        </p>
                    </div>
                </td>
            </tr>
            <template v-else>
                <tr v-for="item of filterStats" >
                    <td class="text-capitalize" style="text-align: left; padding-left: 14px">
                      <img :src="fetchBrowserIcon(item.key)" class="mr-1" style="width: 30px;">
                      {{computeKeyAnalytics(item.key)}}
                    </td>
                    <td style="text-align: center;">{{item.doc_count}}</td>
                    <td style="text-align: center;">{{item.unique_clicks.value}}</td>
                    <td v-if="!forBio" style="text-align: center;">
                        {{item.clicked_conversions.doc_count }}
                    </td>
                    <td v-if="!forBio" style="text-align: center;">

                        {{ Math.round((item.clicked_conversions.doc_count / item.doc_count) * 100 )}}%
                    </td>
                </tr>
            </template>
            </tbody>
        </table>
      <div class="d-flex justify-content-end ">
        <div
          v-if="statistics.aggregations.visited_from_browsers.buckets.length > paginationChunk"
          class="d-flex align-items-center pagination-section">
          <a v-if="!(paginationObj.current <= 0)" @click="pagination(false)">
            <i class="fas fa-arrow-left"></i></a>
          <a v-if="showNext" @click="pagination()"><i
            class="fas ml-2 fa-arrow-right"></i> </a>
        </div>
      </div>
    </div>
</template>
<script>
import orderBy from 'lodash/orderBy'

export default ({
  data () {
    return {
      browser_sorting: 'clicks_desc',
      paginationObj: {
        current: 0,
        next: 0,
        previous: 0,
        chunk: 0
      },
      showNext: false,
      showPrevious: false,
      filterStats: [],
    }
  },
  props: [
    'statistics',
    'forBio',
    'paginationChunk'
  ],
  created() {
    this.init()
  },
  computed: {
    getBrowserList () {
      if (this.statistics.aggregations.visited_from_browsers.buckets.length > 0) {
        const sortingByArray = this.sortingValue(this.browser_sorting)
        let results = orderBy(this.statistics.aggregations.visited_from_browsers.buckets, [sortingByArray[0]], [sortingByArray[1]])
        return results
      }
      return []
    }
  },
  methods: {
    init(stats = this.statistics.aggregations.visited_from_browsers.buckets) {
      this.filterStats = stats
      this.paginationObj.next = this.paginationChunk
      this.paginationObj.chunk = this.paginationChunk
      this.filterStats = stats.slice(this.paginationObj.current, this.paginationObj.chunk)
      this.showNext = stats.length > this.filterStats.length
    },
    pagination(next = true) {
      let filterLength = 0
      let filterStats = this.statistics.aggregations.visited_from_browsers.buckets
      filterLength = filterStats.length
      if (next) {
        if (!this.showNext) return
        this.paginationObj.current = this.paginationObj.next
        this.paginationObj.next = this.paginationObj.next + this.paginationObj.chunk
      } else {
        if (this.paginationObj.current <= 0) return
        this.paginationObj.next = this.paginationObj.next - this.paginationObj.chunk
        this.paginationObj.current = this.paginationObj.next - this.paginationObj.chunk
      }
      this.filterStats = filterStats.slice(this.paginationObj.current, this.paginationObj.next)
      this.showNext = this.paginationObj.next < filterLength
    },
  }
})

</script>
<style lang="less">
        .pagination-section {
          .fa-arrow-right, .fa-arrow-left {
            font-size: 15px;
            color: #2c30b7;
            cursor: pointer;
          }
        }
</style>
